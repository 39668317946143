import React, {useEffect} from "react"
import Layout from "../components/layout"
import ItemPicker from "../components/item-picker"
import Seo from "../components/seo"
import {Auth} from "aws-amplify";
import {navigate} from "gatsby";


const Interests = () => {

    useEffect(async () => {
        try {
            const user = await Auth.currentSession();
        } catch (e) {
            navigate("/");
        }
    }, []);

    return (
        <Layout pageTitle="Interests">
            <Seo title="Interests" />
            <ItemPicker />
        </Layout>
    )
}

export default Interests
