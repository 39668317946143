import React, {useState} from 'react';
import BlockTitle from "./block-title";
import {Container} from "react-bootstrap";
import {Storage} from "aws-amplify";
import { navigate } from "gatsby"
import logoImage from "../assets/images/logo.png";
import HorizontalSpacer from "./horizontal-spacer";

const data = ['Fitness and Health', 'Sports', 'Fashion', 'Art', 'Beauty', 'Photography', 'Travel', 'Food', 'Home', 'Pets & Animals'];

const Button = ({ text, classes, onClick }) => {
    return (
        <button className={classes} onClick={onClick}>
            {text}
        </button>
    );
};

const ItemPicker = () => {
    const [selectedButtons, setSelectedButtons] = useState([]);
    const [inputFieldValue, setInputFieldValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = (index) => {
        const newSelectedButtons = [...selectedButtons];

        if (newSelectedButtons.includes(index)) {
            const itemIndex = newSelectedButtons.indexOf(index);
            newSelectedButtons.splice(itemIndex, 1);
        } else {
            newSelectedButtons.push(index);
        }

        setSelectedButtons(newSelectedButtons);
    };

    const handleSkip = async (event) => {
        event.preventDefault();

        if (!shouldSkip()) {
            await saveUserInterests();
        }

        navigate('/welcome');
    }

    const handleFieldChange = (event) => {
        event.preventDefault();

        setInputFieldValue(event.target.value);
    }

    const getSelectedInterestsAsText = () => {
        return selectedButtons.map(selectedButtonIndex => data[selectedButtonIndex]).toString();
    }

    const saveUserInterests = async () => {
        setIsLoading(true);

        try {
            const key = "uploads/user-data.txt";
            const data = getSelectedInterestsAsText() + ' ' + inputFieldValue;

            await Storage.put(key, data, {
                level: 'private',
                contentType: 'txt',
                tagging: 'object:purpose=user-info',
                useAccelerateEndpoint: true,
                progressCallback(progress) {
                    console.log(progress);
                }
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    const shouldSkip = () => {
        return selectedButtons.length === 0 && inputFieldValue === '';
    }

    const renderButtonCaption = () => {
        return shouldSkip() ? 'Skip' : 'Confirm';
    }

    return (
        <section className="subscribe-style-one center-children-horizontal-vertical">
            <Container>
                <BlockTitle
                    textAlign="center"
                    image={logoImage}
                    title={``}
                    showImage={true}
                />
                <h5 className="text-center">
                    Pick a few topics you like, are passionate about or would like to explore.
                </h5>
                <HorizontalSpacer height={50} />
                <div className="item-picker-container">
                    {data.map((item, index) => (
                        <Button
                            key={index}
                            text={item}
                            classes={`item-element ${selectedButtons.includes(index) ? 'item-element-selected' : "item-element-deselected"}`}
                            selected={selectedButtons.includes(index)}
                            onClick={() => handleClick(index)}
                        />
                    ))}
                </div>
                <HorizontalSpacer height={50} />
                <h5 className="text-center">
                    You can also connect your Instagram account -- this will give Fanster a good idea of what you like.
                </h5>
                <HorizontalSpacer height={50} />
                <form className="subscribe-form contact-form-vaidated block">
                    <input onChange={handleFieldChange} className="full-width" type="text" name="igAccount" placeholder="Instagram Profile URL" />
                    <HorizontalSpacer height={20} />
                    <button onClick={handleSkip} className={isLoading ? 'next-button button-loading' : 'next-button'} type="submit">{renderButtonCaption()}</button>
                </form>
            </Container>
        </section>
    );
};

export default ItemPicker
